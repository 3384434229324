/* Font Specs */

/* Heavy */
@font-face {
  font-family: "AvenirLTStd";
  src: url('../public/assets/font/AvenirLTStd-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* Heavy Italic*/
@font-face {
  font-family: "Boston";
  src: url('../public/assets/font/AvenirLTStd-HeavyOblique.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

/* Black */
@font-face {
  font-family: "AvenirLTStd";
  src: url('../public/assets/font/AvenirLTStd-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

/* Black Italic */
@font-face {
  font-family: "AvenirLTStd";
  src: url('../public/assets/font/AvenirLTStd-BlackOblique.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

/* Bold */
@font-face {
  font-family: "AvenirLTStd";
  src: url('../public/assets/font/AvenirLTStd-Book.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

/* Bold Italic */
@font-face {
  font-family: "AvenirLTStd";
  src: url('../public/assets/font/AvenirLTStd-BookOblique.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

/* Semi Bold */
@font-face {
  font-family: "AvenirLTStd";
  src: url('../public/assets/font/AvenirLTStd-Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

/* Semi Bold Italic */
@font-face {
  font-family: "AvenirLTStd";
  src: url('../public/assets/font/AvenirLTStd-MediumOblique.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

/* Regular */
@font-face {
  font-family: "AvenirLTStd";
  src: url('../public/assets/font/AvenirLTStd-Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

/* Regular Italic */
@font-face {
  font-family: "AvenirLTStd";
  src: url('../public/assets/font/AvenirLTStd-Oblique.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

/* Light */
@font-face {
  font-family: "AvenirLTStd";
  src: url('../public/assets/font/AvenirLTStd-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* Light Italic */
@font-face {
  font-family: "AvenirLTStd";
  src: url('../public/assets/font/AvenirLTStd-LightOblique.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

/* General definitions */

body {
  margin: 0;
  font-family: "AvenirLTStd";
  overflow-x: hidden;
}

ul {
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

* {
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Homepage */

.Hero {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.HeroP {
  overflow: hidden;
  width: 100vw;
  height: 50vh;
}

/* Header */

header {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100vw;
  height: auto;
  flex-flow: row;
  justify-content: center;
}

header>div {
  margin-top: 5%;
  display: flex;
  width: calc(100% - 10vw);
  height: auto;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
}

header>div div {
  height: auto;
  display: flex;
  flex-flow: row;
}

header>div div:first-of-type {
  width: 5%;
}

header>div div:nth-of-type(2) {
  width: 13%;
}

header a {
  width: 100%;
}

/* Carousel Homepage & Projects/Atelier*/

.P-Container {
  z-index: 0;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 50vh;
  overflow: hidden;
}

.P-Container .carousel-item img {
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
}

.C-Container {
  z-index: 0;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.carousel {
  width: 100%;
  position: relative;
  display: flex;
  flex-grow: row;
  justify-content: center;
}

.carousel-inner {
  width: 100%;
  height: 100vh;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.carousel-item {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s !important;
}

.active.carousel-item {
  opacity: 1;
  transition: opacity 0.5s !important;
}

.active {
  opacity: 1;
}

.carousel-item p {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 7%;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
}

.carousel-indicators {
  width: 50%;
  display: flex;
  flex-flow: row;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  bottom: 0;
  top: 95%;
  z-index: 1;
  justify-content: center;
}

button {
  width: 15px;
  height: 15px;
  border: 0;
  color: white;
  opacity: 0.5;
  margin-left: 2%;
}

/* Homepage */

.info-Container {
  margin-top: 7.5%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: auto;
}

.info-Container div {
  width: calc(100% - 30vw);
  display: flex;
}

.info-Container div p {
  font-weight: 500;
  font-size: calc(14px + 0.390625vw);
}

.info-Container div h1 a {
  font-weight: 800;
  text-decoration: none;
  color: black;
  font-size: calc(25px + 0.390625vw);
}

.contact-Container {
  margin-top: 7.5%;
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: auto;
}

#line {
  margin-top: 2%;
  width: calc(100% - 30vw);
  border-top-style: solid;
  border-top-color: black;
  border-top-width: 1px;
}

h3 {
  margin-top: 3%;
  font-weight: 800;
  font-size: calc(20px + 0.390625vw);
}

#contact {
  display: flex;
  flex-flow: column;
}

.contact-info {
  margin-bottom: 1%;
  font-size: calc(14px + 0.390625vw);
}

/* Atelier/Projects */

.AP-info-Container {
  margin-top: 1%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: auto;
}

.AP-info-Container>div {
  width: calc(100% - 10vw);
  display: flex;
  flex-flow: column;
}

#nav ul {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
}

#nav {
  width: 100%;
  display: flex;
}

#nav li {
  margin-left: 1%;
  font-size: calc(12px + 0.390625vw);
  font-weight: 750;
}

#nav li:nth-of-type(2) {
  margin-bottom: 0.5%;
}

.Atelier,
.Projects {
  width: 100%;
  display: flex;
  flex-flow: column;
  margin-bottom: 7.5%;
}

.Atelier h1,
.Projects h1 {
  font-weight: 800;
  text-decoration: none;
  color: black;
  font-size: calc(25px + 0.390625vw);
  margin-bottom: 2.5%;
}

#atxt {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

#atxt p {
  width: 44%;
}

.AP-info-Container p {
  font-weight: 500;
  font-size: calc(14px + 0.390625vw);
}

#plist {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.build {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 44%;
  margin-bottom: 2.5%;
}

.build h4 {
  font-weight: 750;
  font-size: calc(16px + 0.390625vw);
  margin: 0;
}

.build ul {
  width: 100%;
  font-weight: 500;
  font-size: calc(14px + 0.390625vw);
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
}

.build ul li {
  margin-right: 1.5%;
}

.build>ul>li:nth-of-type(2),
.build ul li:nth-of-type(4) {
  margin-bottom: 1%;
}

/* Footer */

footer {
  margin-top: 7.5%;
  margin-bottom: 5%;
  width: 100vw;
  height: auto;
  display: flex;
  flex-flow: row;
  justify-content: center;
  font-size: calc(7px + 0.390625vw);
}

footer>div {
  width: calc(100% - 10vw);
  height: auto;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

footer>div div:first-of-type {
  width: 25%;
}

footer>div div:nth-of-type(2) {
  width: 75%;
  display: flex;
  flex-flow: row;
}

footer ul {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
}

footer li:nth-of-type(2),
footer li:nth-of-type(4) {
  margin-right: 2%;
  margin-left: 2%;
  font-size: calc(10px + 0.390625vw);
  margin-bottom: 1%;
}

/* Error 404 */

.E-Container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
}

#error {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

#error h1 {
  font-weight: 800;
  text-decoration: none;
  color: black;
  font-size: calc(45px + 0.390625vw);
  margin: 0;
}

#error span {
  margin: 0;
  font-weight: 500;
  font-size: calc(25px + 0.390625vw);
}

/* Responsive */

@media screen and (max-width:650px) {

  .Atelier h1,
  .Projects h1 {
    margin-bottom: 10%;
  }

  #atxt {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  #atxt p {
    width: 100%;
  }

  #plist {
    flex-flow: column;
  }

  .build {
    width: 100%;
    margin-bottom: 5%;
  }

  .contact-info {
    margin-bottom: 5%;
  }

  .carousel-item p {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 14%;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
  }

  .carousel-indicators {
    width: 50%;
    display: flex;
    flex-flow: row;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    bottom: 0;
    top: 90%;
    z-index: 1;
    justify-content: center;
  }
}

@media screen and (max-width: 950px) {

  #nav {
    margin-top: 1%;
  }

  footer {
    flex-flow: column;
    align-items: center;
  }

  footer>div {
    flex-flow: column;
    align-items: flex-start;
  }

  footer>div div:first-of-type {
    width: 100%;
  }

  footer>div div:nth-of-type(2) {
    width: 100%;
  }

  footer ul {
    padding: 0;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1000px) {
  header>div div:first-of-type {
    width: 11.5%;
  }

  header>div div:nth-of-type(2) {
    width: 26.5%;
  }
}

@media screen and (max-width: 1377px) {

  .C-Container {
    z-index: 0;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .carousel {
    height: 100%;
    position: relative;
  }

  .carousel-inner {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .carousel-item {
    height: 100%;
    display: block;
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s !important;
  }

  .carousel-item img {
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
  }
}